import { Table, TableHeader, TableHeaderCell, makeStyles } from '@fluentui/react-components';
import { useRef } from 'react';
import { customTokens } from '../../../../styles';
import CopyCard from './CopyCard';


const useClasses = makeStyles({
    BrandBackground: {
        backgroundColor: customTokens.colorBrandBackground,
    },
    BrandColor: {
        color: customTokens.colorNeutralForegroundOnBrand,
    }
});

export const TableRenderer = (props: any) => {
    const tableRef = useRef<HTMLTableElement>(null);

    // Removed 'async' since there is no await statement inside
    const copyTableContent = (): string => {
        if (!tableRef.current) return '';

        const rows = Array.from(tableRef.current.querySelectorAll('tr'));
        const tsvData = rows
            .map((row) => {
                const cells = Array.from(row.querySelectorAll('th, td'));
                return cells.map((cell) => (cell.textContent ?? '').trim()).join('\t');
            })
            .join('\n');

        return tsvData;
    };

    return (
        <CopyCard copyContent={copyTableContent} copyLabel="Copy table" cardBrandBackground={false} >
            <Table size="small" ref={tableRef} {...props} />
        </CopyCard>
    );
};

export const TheadRenderer = ({ _, ...props }: any) => {
    const classes = useClasses();

    return <TableHeader className={classes.BrandBackground} {...props} />;
};

export const ThRenderer = (props: any) => {
    const classes = useClasses();
    return <TableHeaderCell className={classes.BrandColor} {...props} />;
};