import {
	Button,
	Text,
	Tooltip,
	makeStyles,
	mergeClasses,
	shorthands
} from '@fluentui/react-components';
import { Clipboard20Regular, ClipboardTask20Regular } from '@fluentui/react-icons';
import React, { ReactNode, useState } from 'react';
import { customTokens } from '../../../../styles';

const useClasses = makeStyles({
    item: {
        ...shorthands.borderRadius(customTokens.borderRadiusMedium),
        ...shorthands.padding(customTokens.spacingVerticalXS, customTokens.spacingHorizontalNone),
    },
    BrandBackground: {
        backgroundColor: customTokens.colorBrandBackground,
        color: customTokens.colorNeutralForegroundOnBrand,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        ...shorthands.margin(customTokens.spacingVerticalNone, customTokens.spacingHorizontalS),
    },
    copyButton: {
        marginLeft: 'auto', // align to right
    },
    content: {
        backgroundColor: customTokens.colorNeutralBackground1,
    }
});

interface CardWithCopyButtonProps {
    children: ReactNode;
    copyContent: string | (() => string | Promise<string>);
    copyLabel?: string;
    cardLabel?: string;
    cardBrandBackground?: boolean | undefined | null;
}

const CardWithCopyButton: React.FC<CardWithCopyButtonProps> = ({ children, copyContent, copyLabel = 'Copy content', cardLabel, cardBrandBackground = true }) => {
    const [messageCopied, setMessageCopied] = useState(false);

    const handleCopy = async () => {
        try {
            // Allow copyContent to be a string or a function that returns either a string or a Promise<string>
            const content = typeof copyContent === 'string' ? copyContent : await copyContent();
            await navigator.clipboard.writeText(content);
            setMessageCopied(true);
            setTimeout(() => { setMessageCopied(false) }, 2000);
        } catch (err) {
            console.error('Failed to copy:', err);
        }
    };
    
    const classes = useClasses();

    return (
        <div className={cardBrandBackground ? mergeClasses(classes.item, classes.BrandBackground) : classes.item}>
            <div className={classes.header}>
                {cardLabel && <Text weight="semibold">{cardLabel}</Text>}
                <Tooltip content={ messageCopied? 'Copied': copyLabel} relationship="label">
                    <Button
                        icon={messageCopied ? <ClipboardTask20Regular /> : <Clipboard20Regular />}
                        appearance={cardBrandBackground ? "primary" : "subtle"}
                        onClick={() => {
                            void handleCopy();
                        }}
                        className={classes.copyButton}
                    />
                </Tooltip>
            </div>
            <div className={classes.content}>
                {children}
            </div>
        </div>
    );
};

export default CardWithCopyButton;
