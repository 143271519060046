import { makeStyles, mergeClasses } from '@fluentui/react-components';
import hljs from 'highlight.js'; // Import the highlight.js library
import 'highlight.js/styles/github-dark.css';
import React, { useEffect, useRef } from 'react';
import { ScrollBarStyles } from '../../../../styles';
import CopyCard from './CopyCard';

// Define the props interface
interface CodeHighlighterProps {
    className?: string;
    code: string;
}

const CodeHighlighter: React.FC<CodeHighlighterProps> = ({ className, code }) => {
    const codeRef = useRef<HTMLElement | null>(null); // Type the ref as an HTMLElement

    useEffect(() => {
        if (codeRef.current) {
            // unset dataset.highlighted
            codeRef.current.removeAttribute('data-highlighted');
            hljs.highlightElement(codeRef.current); // Apply highlight.js to the code block
    }}, [code]); // Re-run the highlight whenever the code changes

    return (
        <code ref={codeRef} className={className}>
            {code}
        </code>
    );
};

const useClasses = makeStyles({
    code: {
        ...ScrollBarStyles,
    },
});

export interface CodeRendererProps {
    className?: string; // className is optional
    children?: React.ReactNode;
}

const CodeRenderer = ({ className, children, ...props }: CodeRendererProps) => {
    const classes = useClasses();

    // Ensure className is a string and match can be null, so we handle it safely
    const match = /language-(\w+)/.exec(className ?? '');

    return match ? (
        <CopyCard copyContent={String(children)} copyLabel="Copy code" cardLabel={match[1]}>
            <CodeHighlighter className={mergeClasses(className, match[1], classes.code)} code={String(children).replace(/\n$/, '')} />
        </CopyCard>
    ) : (
        <code className={mergeClasses(className, classes.code)} {...props}>
            {children}
        </code>
    );
};

export default CodeRenderer;